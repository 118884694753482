import { useQuery, useMutation } from 'react-query';
import axiosClient from './axiosClient';

export const storeTypeApi = {
  getAll(params) {
    return axiosClient.get('/stores/types', { params }).then((response) => {
      return response?.data;
    });
  },
  create(data) {
    return axiosClient.post('/stores/types', data);
  },
  update(id, data) {
    return axiosClient.patch(`/stores/types/${id}`, data);
  },
  multipleDelete(ids) {
    return axiosClient.delete('/stores/types/delete/multiple', {
      data: { ids },
    });
  },
};

export const useStoreTypes = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['store-types', 'list', params],
    queryFn: () => storeTypeApi.getAll(params),
    ...options,
  });
};

export const useCreateStoreType = () => {
  return useMutation(storeTypeApi.create);
};

export const useUpdateStoreType = (id) => {
  return useMutation((data) => storeTypeApi.update(id, data));
};

export const useDeleteMultipleStoreTypes = () => {
  return useMutation({
    mutationFn: (ids) => storeTypeApi.multipleDelete(ids),
  });
};
