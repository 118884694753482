import { useStoreStatuses } from 'api/storeApi';
import { StyledSelect } from 'styles/overrides';
import { normalizeOptions, filterOptions } from 'utils/formatUtils';

export default function StoreStatusSelector({ onChange, value, ...restProps }) {
  const { data = [], isLoading } = useStoreStatuses({
    options: {
      select: (response) => normalizeOptions(response, 'name', 'key'),
    },
  });

  return (
    <StyledSelect
      options={data}
      loading={isLoading}
      showSearch
      optionFilterProp="label"
      filterOption={filterOptions}
      {...restProps}
      onChange={onChange}
      value={value}
    />
  );
}
