import { useIntl } from 'react-intl';
import { Form } from 'antd';
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledInput,
} from 'styles/overrides';
import { NoteGroupSelector } from 'components/selectors';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';

export default function PlanNoteForm({ onSubmit = () => {} }) {
  const [form] = Form.useForm();
  const intl = useIntl();

  const handleSubmit = ({ group_id_type, ...values }) => {
    form.resetFields();
    onSubmit({ ...values, ...JSON.parse(group_id_type) });
  };

  return (
    <StyledForm layout="inline" form={form} onFinish={handleSubmit}>
      <StyledFormItem
        name="group_id_type"
        rules={[{ required: true, message: false }]}
        style={{ marginBottom: 0 }}
      >
        <NoteGroupSelector
          style={{ minWidth: 200 }}
          placeholder={intl.formatMessage({ id: 'common.placeholder.common' })}
        />
      </StyledFormItem>
      <StyledFormItem
        name="content"
        rules={[{ required: true, message: false }]}
        style={{ marginBottom: 0 }}
      >
        <StyledInput
          placeholder={intl.formatMessage({ id: 'common.placeholder.content' })}
        />
      </StyledFormItem>

      <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC, ROLE.SUP]}>
        <StyledFormItem>
          <StyledButton type="danger" htmlType="submit">
            {intl.formatMessage({ id: 'common.btn.update' })}
          </StyledButton>
        </StyledFormItem>
      </Permission>
    </StyledForm>
  );
}
