import _ from 'lodash';
import { Form, Row, Col, Checkbox, Typography } from 'antd';
import {
  StyledForm,
  StyledFormItem,
  StyledCollapse,
  StyledCollapsePanel,
  StyledButton,
  StyledSpace,
} from 'styles/overrides';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';

export default function PlanQCForm({
  qcs = [],
  onSubmit = () => {},
  initialValues,
}) {
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    const data = _.reduce(
      values,
      (result, value) => {
        if (value?.length > 0) {
          result.push(...value);
        }

        return result;
      },
      [],
    );

    onSubmit({
      qc_id: data,
    });
  };

  return (
    <StyledForm
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
    >
      <Row gutter={[24, 24]}>
        {_.map(qcs, ({ id, display, children }) => (
          <Col span={8} key={id}>
            <StyledCollapse>
              <StyledCollapsePanel
                header={display}
                key={id}
                forceRender
                extra={
                  <StyledFormItem
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues[id] !== currentValues[id]
                    }
                  >
                    {({ getFieldValue }) => {
                      const quantity = getFieldValue(id)?.length ?? 0;

                      return (
                        <Typography.Text
                          type="danger"
                          style={{
                            padding: '4px 8px',
                            borderRadius: 4,
                            backgroundColor: 'var(--white)',
                          }}
                        >
                          {quantity}
                        </Typography.Text>
                      );
                    }}
                  </StyledFormItem>
                }
              >
                {children?.length > 0 && (
                  <StyledFormItem name={id} style={{ marginBottom: 0 }}>
                    <Checkbox.Group>
                      <StyledSpace direction="vertical">
                        {_.map(children, (item) => (
                          <Checkbox key={item.id} value={item.id}>
                            {item.display}
                          </Checkbox>
                        ))}
                      </StyledSpace>
                    </Checkbox.Group>
                  </StyledFormItem>
                )}
              </StyledCollapsePanel>
            </StyledCollapse>
          </Col>
        ))}

        <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC]}>
          <Col span={24}>
            <StyledFormItem style={{ textAlign: 'center', marginBottom: 0 }}>
              <StyledButton htmlType="submit" type="danger">
                Lưu
              </StyledButton>
            </StyledFormItem>
          </Col>
        </Permission>
      </Row>
    </StyledForm>
  );
}
