import { useQuery } from 'react-query';
import axiosClient from './axiosClient';

export const dashboardApi = {
  getStoreMap(params) {
    return axiosClient
      .get('/stores/map', { params })
      .then((response) => response?.data ?? []);
  },
  getStoreMapMultiple(params) {
    return axiosClient
      .post('/stores/map/multiple', params )
      .then((response) => response?.data ?? []);
  },
  progressChart(params) {
    return axiosClient
      .get('/admin/dashboard/progress-chart', { params })
      .then((response) => response?.data);
  },
  performanceProgress(params) {
    return axiosClient
      .get('/admin/dashboards/implementation-progress', { params })
      .then((response) => response?.data);
  },
  getMapData(params) {
    return axiosClient
      .get('/admin/map/survey-result', { params })
      .then((response) => response?.data);
  },
  getTracking(params) {
    return axiosClient
      .get('/admin/tracking', { params })
      .then((response) => response?.data);
  },
  getMilkGroupChart(params) {
    return axiosClient
      .get('/admin/dashboard/milk-group-chart', { params })
      .then((response) => response?.data);
  },
  getPosmChart(params) {
    return axiosClient
      .get('/admin/dashboard/posm-chart', { params })
      .then((response) => response?.data);
  },
};

export const useProgressChart = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'progress-chart', params],
    queryFn: () => dashboardApi.progressChart(params),
    ...options,
  });
};

export const usePerformanceProgress = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'performance-progress', params],
    queryFn: () => dashboardApi.performanceProgress(params),
    ...options,
  });
};

export const useGetMapData = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'map', params],
    queryFn: () => dashboardApi.getMapData(params),
    ...options,
  });
};

export const useGetTracking = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'tracking', params],
    queryFn: () => dashboardApi.getTracking(params),
    ...options,
  });
};

export const useMilkGroupChart = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'milk-group-chart', params],
    queryFn: () => dashboardApi.getMilkGroupChart(params),
    ...options,
  });
};

export const usePosmChart = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'posm-chart', params],
    queryFn: () => dashboardApi.getPosmChart(params),
    ...options,
  });
};

export const useGetStoreMap = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'store-map', params],
    queryFn: () => dashboardApi.getStoreMap(params),
    ...options,
  });
};

export const useGetStoreMapMultiple = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['dashboard', 'store-map', params],
    queryFn: () => dashboardApi.getStoreMapMultiple(params),
    ...options,
  });
};
