import { useQuery } from 'react-query';
import axiosClient from './axiosClient';

export const publicApi = {
  getQCs(params) {
    return axiosClient.get('/qc', { params });
  },
  getRepairStatuses(params) {
    return axiosClient
      .get('/repairs/status', { params })
      .then((response) => response?.data ?? []);
  },
};

export const useQCs = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['qcs', 'list', params],
    queryFn: () => publicApi.getQCs(params),
    ...options,
  });
};

export const useRepairStatuses = ({ params, options } = {}) => {
  return useQuery({
    queryKey: ['repair-status', 'list', params],
    queryFn: () => publicApi.getRepairStatuses(params),
    ...options,
  });
};
