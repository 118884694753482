import { useIntl } from 'react-intl';
import { Row, Col, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { StyledForm, StyledFormItem } from 'styles/overrides';
import { FileGroupSelector } from 'components/selectors';

const normFile = (e) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e?.fileList;
};

export default function PlanUploadImageForm({ form }) {
  const intl = useIntl();

  const handleBeforeUpload = (file) => {
    // TODO: VALIDATION FILE

    return false;
  };

  return (
    <StyledForm layout="vertical" size="large" form={form}>
      <Row gutter={[32, 0]}>
        <Col span={24}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.plans.form.label.image',
            })}
            name="files"
            valuePropName="fileList"
            rules={[{ required: true }]}
            getValueFromEvent={normFile}
          >
            <Upload
              listType="picture-card"
              multiple
              beforeUpload={handleBeforeUpload}
              showUploadList={{ showPreviewIcon: false }}
              accept="image/*"
            >
              <PlusOutlined style={{ fontSize: 24 }} />
            </Upload>
          </StyledFormItem>
        </Col>

        <Col span={24}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.plans.form.label.fileGroup',
            })}
            name="group_id"
            rules={[{ required: true }]}
          >
            <FileGroupSelector />
          </StyledFormItem>
        </Col>
      </Row>
    </StyledForm>
  );
}
