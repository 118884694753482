import { useMemo, useRef, useState } from 'react';
import { Spin } from 'antd';
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  InfoWindow,
  StandaloneSearchBox,
  Data,
} from '@react-google-maps/api';
import _ from 'lodash';
import MapInfoWindow from '../MapInfoWindow/MapInfoWindow';
import { Modal } from 'components/modal';
const DEFAULT_CENTER = {
  lat: 10.754792,
  lng: 106.6952276,
};

const libraries = ['places']
function MapModal({
  isLoading = false,
  markers = [],
  trackings = [],
  isModalOpen,
  onCancel,
}) {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [searchBox, setSearchBox] = useState(null);
  const [searchedPlace, setSearchedPlace] = useState(null);
  const [clickedPlace, setClickedPlace] = useState(null)
  const [center, setCenter] = useState({
    lat:  DEFAULT_CENTER.lat,
    lng:  DEFAULT_CENTER.lng,
  })
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    libraries: libraries
  });

  const handlePlaceChange = () => {
    setClickedPlace(null)

    const place = searchBox.getPlaces();
    if (!place || place.lenght > 0) return;
    const lat = place[0].geometry.location.lat();
    const lng = place[0].geometry.location.lng();
    setSearchedPlace({ lat: lat, lng: lng });
    setCenter({ lat: +lat, lng: +lng })
  };

  const handlePlaceClicked = (geometry) => {
    setSearchedPlace(null)
    const clickedPlace = geometry.g
    if (!clickedPlace) return;
    const lat = clickedPlace.lat();
    const lng = clickedPlace.lng();
    setClickedPlace({ lat: lat, lng: lng })

    console.log('clicked place:' + lat, lng);
  }
  const onSearchBoxLoad = (ref) => {
    setSearchBox(ref);
  };
  const onDataLoad = (data) => {
    // console.log(data);
  };
//   const center = useMemo(() => {
//     return {
//       lat:  searchedPlace?.lat || +markers?.[0]?.lat || DEFAULT_CENTER.lat,
//       lng:  searchedPlace?.long || +markers?.[0]?.long || DEFAULT_CENTER.lng,
//     };
//   }, [markers, searchedPlace]);
  
  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return (
    <Modal size='large' open={isModalOpen} onCancel={onCancel}>
      <Spin spinning={!isLoaded || isLoading}>
        {isLoaded && (
          <GoogleMap
            mapContainerStyle={{ width: '100%', height: 600 }}
            center={center}
            zoom={15}
            onClick={() => setSelectedMarker(null)}
          >
            {_.map(markers, (marker) => {
              const { lat, long, color, id } = marker;

              return (
                <Marker
                  key={id}
                  position={{ lat: +lat, lng: +long }}
                  options={{
                    icon: {
                      path: 'M7.8,1.3L7.8,1.3C6-0.4,3.1-0.4,1.3,1.3c-1.8,1.7-1.8,4.6-0.1,6.3c0,0,0,0,0.1,0.1 l3.2,3.2l3.2-3.2C9.6,6,9.6,3.2,7.8,1.3C7.9,1.4,7.9,1.4,7.8,1.3z M4.6,5.8c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.3,1.4-1.3 c0.7,0,1.3,0.6,1.3,1.3 C5.9,5.3,5.3,5.9,4.6,5.8z',
                      fillColor: color,
                      fillOpacity: 1,
                      strokeColor: 'white',
                      strokeWeight: 1,
                      scale: 3.5,
                    },
                  }}
                  onClick={() => setSelectedMarker(marker)}
                />
              );
            })}

            {selectedMarker && (
              <InfoWindow
                position={{
                  lat: +selectedMarker.lat,
                  lng: +selectedMarker.long,
                }}
                onCloseClick={() => setSelectedMarker(null)}
              >
                <MapInfoWindow marker={selectedMarker} />
              </InfoWindow>
            )}
            <StandaloneSearchBox
              onPlacesChanged={handlePlaceChange}
              onLoad={onSearchBoxLoad}
            >
              <input
                type="text"
                placeholder="Nhập nơi cần tìm"
                style={{
                  boxSizing: `border-box`,
                  border: `1px solid transparent`,
                  width: `240px`,
                  height: `32px`,
                  padding: `0 12px`,
                  borderRadius: `3px`,
                  boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                  fontSize: `14px`,
                  outline: `none`,
                  textOverflow: `ellipses`,
                  position: 'absolute',
                  left: '50%',
                  marginLeft: '-120px',
                }}
              />
            </StandaloneSearchBox>

            {searchedPlace && (
              <Marker
                position={{ lat: +searchedPlace.lat, lng: +searchedPlace.lng }}
              />
            )}
            {clickedPlace && (
              <Marker
                position={{ lat: +clickedPlace.lat, lng: +clickedPlace.lng }}
              />
            )}
            <Data
              onLoad={onDataLoad}
              options={{
                controlPosition: window.google
                  ? window.google.maps.ControlPosition.TOP_LEFT
                  : undefined,
                controls: ['Point'],
                drawingMode: 'Point', //  "LineString" or "Polygon".
                featureFactory: (geometry) => {
                    handlePlaceClicked(geometry);
                },
                clickable: true,
                draggable: true,
                editable: false,
                title: 'Title',
                visible: true,
                zIndex: 2,
              }}
            />
          </GoogleMap>
        )}
      </Spin>
    </Modal>
  );
}
export default MapModal