import _ from 'lodash';
import { useIntl } from 'react-intl';
import { Spin, Row, Col, Typography } from 'antd';
import { StyledCard, StyledSpace } from 'styles/overrides';
import { useConfirmPlanQC, usePlanQCInfo } from 'api/planApi';
import { Notification } from 'components/Notification';
import { StyledButton } from 'styles/overrides';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';
import { formatTime } from 'utils/timeUtils';

export default function PlanQCConfirm({ planId, onSuccess = () => {} }) {
  const intl = useIntl();
  const confirmPlanQC = useConfirmPlanQC(planId);
  const {
    data: planQCInfo,
    isLoading: isLoadingPlanQCInfo,
    refetch,
  } = usePlanQCInfo({
    id: planId,
    options: {
      select: (response) => response?.data,
    },
  });

  const handleConfirmQC = () => {
    confirmPlanQC.mutate(
      { qc_status: 1 },
      {
        onSuccess() {
          refetch();
          Notification(
            'success',
            intl.formatMessage({ id: 'message.commonSuccess' }),
          );
        },
        onError(error) {
          Notification(
            'error',
            error?.message || intl.formatMessage({ id: 'message.commonError' }),
          );
        },
      },
    );
  };

  return (
    <Spin spinning={confirmPlanQC.isLoading || isLoadingPlanQCInfo}>
      <StyledCard
        title={intl.formatMessage({ id: 'views.plans.planQCConfirm.title' })}
        size="small"
      >
        <Row gutter={[24, 24]}>
          {_.map(planQCInfo, ({ id, display_name, info }) => (
            <Col span={24} sm={12} key={id}>
              <Typography.Title level={5}>{display_name}</Typography.Title>

              {info?.length > 0 && (
                <StyledSpace direction="vertical">
                  {_.map(info, (item) => (
                    <Typography.Text key={item.id}>
                      {item.user?.full_name ?? item.user?.name} -{' '}
                      {formatTime(item.created_at)}
                    </Typography.Text>
                  ))}
                </StyledSpace>
              )}
            </Col>
          ))}
        </Row>

        <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC, ROLE.SUP]}>
          <div style={{ textAlign: 'center', marginTop: 16 }}>
            <StyledButton type="danger" onClick={handleConfirmQC}>
              {intl.formatMessage({ id: 'common.btn.confirmQC' })}
            </StyledButton>
          </div>
        </Permission>
      </StyledCard>
    </Spin>
  );
}
