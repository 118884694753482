import React, { useEffect, useMemo } from 'react';
import _ from 'lodash';
import { Form, Radio } from 'antd';
import { StyledFormItem, StyledInput } from 'styles/overrides';
import RenderComponent from '../RenderComponent/RenderComponent';
import RenderAudio from '../RenderAudio/RenderAudio';

export default function RenderRadioComponent({
  component,
  className,
  onShowHideQuestions = () => {},
}) {
  const formInstance = Form.useFormInstance();
  const {
    required,
    is_confirm,
    is_record,
    answers,
    question_children,
    id,
    name,
    default_answer_id,
  } = component;

  const watchSelectField = Form.useWatch([id, 'answer_id'], formInstance);

  const options = useMemo(
    () => _.map(answers, (item) => ({ label: item.name, value: item.id })),
    [answers],
  );

  const mappingAnswers = useMemo(() => {
    return _.reduce(
      answers,
      (acc, item) => {
        acc[item.id] = item;

        return acc;
      },
      {},
    );
  }, [answers]);

  useEffect(() => {
    if (mappingAnswers[watchSelectField]) {
      const { show_question_ids, hide_question_ids } =
        mappingAnswers[watchSelectField];

      if (!show_question_ids && !hide_question_ids) {
        return;
      }

      onShowHideQuestions({
        showQuestions: show_question_ids,
        hideQuestions: hide_question_ids,
      });
    }
  }, [watchSelectField]);

  const isQuestionChildren =
    !is_confirm && answers.length === 0 && question_children.length > 0;

  return (
    <div>
      {isQuestionChildren ? (
        <>
          <StyledFormItem
            label={name}
            labelCol={{ span: 24 }}
            className={className}
          />
          {_.map(question_children, (item) => (
            <RenderComponent key={item.id} component={item} />
          ))}
        </>
      ) : (
        <>
          <StyledFormItem
            label={name}
            name={[id, 'answer_id']}
            rules={[{ required: !!required, message: 'Bắt buộc' }]}
            initialValue={default_answer_id}
            className={className}
          >
            <Radio.Group options={options} />
          </StyledFormItem>

          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues?.[id]?.['answer_id'] !==
              currentValues?.[id]?.['answer_id']
            }
          >
            {({ getFieldValue }) => {
              const selectedValue = getFieldValue([id, 'answer_id']);
              const isShowInput = !!mappingAnswers[selectedValue]?.show_input;
              const isYesAnswer =
                mappingAnswers[selectedValue]?.name === 'Có' && !!is_confirm;
              const isNoAnswer =
                mappingAnswers[selectedValue]?.name === 'Không' &&
                !!is_confirm &&
                !!is_record;

              if (isShowInput) {
                return (
                  <StyledFormItem
                    name={[id, 'content']}
                    label="Ghi rõ"
                    rules={[{ required: true, message: 'Bắt buộc' }]}
                  >
                    <StyledInput />
                  </StyledFormItem>
                );
              }

              if (isYesAnswer) {
                return _.map(question_children, (item) => (
                  <RenderComponent key={item.id} component={item} />
                ));
              }

              if (isNoAnswer) {
                return <RenderAudio id={id} />;
              }

              return null;
            }}
          </Form.Item>
        </>
      )}
    </div>
  );
}
