import { useIntl } from 'react-intl';
import { StyledSelect } from 'styles/overrides';
import { useRepairStatuses } from 'api/publicApi';
import { filterOptions, normalizeOptions } from 'utils/formatUtils';

export default function RepairStatusSelector({
  onChange,
  value,
  ...restProps
}) {
  const intl = useIntl();

  const { data = [], isLoading } = useRepairStatuses({
    options: {
      select: (response) => normalizeOptions(response, 'name', 'key'),
    },
  });

  return (
    <StyledSelect
      options={data}
      loading={isLoading}
      placeholder={intl.formatMessage({
        id: 'common.placeholder.repairStatus',
      })}
      showSearch
      optionFilterProp="label"
      filterOption={filterOptions}
      {...restProps}
      onChange={onChange}
      value={value}
      dropdownMatchSelectWidth={false}
    />
  );
}
