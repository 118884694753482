import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { StyledCard } from 'styles/overrides';
import { Descriptions, Image } from 'antd';
import { formatAddress } from 'utils/formatUtils';
import { formatTime } from 'utils/timeUtils';
import _ from 'lodash';
import { PATH_NAME } from 'constants/routes';
import { TEXT_DEFAULT } from 'constants/common';

export default function PlanStore({ store }) {
  const intl = useIntl();

  const {
    id,
    code,
    store_name,
    common_name,
    phone,
    address,
    street,
    ward,
    district,
    province,
    created_at,
    images,
    lat,
    long,
    market,
  } = store;

  const formattedAddress = formatAddress({
    address,
    street,
    ward: ward?.name,
    district: district?.name,
    province: province?.name,
  });

  return (
    <StyledCard
      title={intl.formatMessage({ id: 'views.plans.planStore.title' })}
      bordered={false}
      bodyStyle={{ padding: 0 }}
      size="small"
    >
      <Descriptions column={1} bordered size="small">
        <Descriptions.Item
          label={intl.formatMessage({
            id: 'views.plans.planStore.storeOverview',
          })}
        >
          <Image.PreviewGroup>
            {_.map(images, (image) => (
              <Image key={image.id} src={image.path} width={50} height={50} />
            ))}
          </Image.PreviewGroup>
        </Descriptions.Item>

        <Descriptions.Item
          label={intl.formatMessage({ id: 'views.plans.planStore.storeName' })}
        >
          {store_name} {common_name && `(${common_name})`}
        </Descriptions.Item>

        <Descriptions.Item
          label={intl.formatMessage({ id: 'views.plans.planStore.storeCode' })}
        >
          <Link
            to={`/${PATH_NAME.STORES}/${id}`}
            style={{ color: 'var(--primary)' }}
            target="_blank"
          >
            {code}
          </Link>
        </Descriptions.Item>

        <Descriptions.Item
          label={intl.formatMessage({ id: 'views.plans.planStore.storePhone' })}
        >
          {phone}
        </Descriptions.Item>

        <Descriptions.Item
          label={intl.formatMessage({
            id: 'views.plans.planStore.storeAddress',
          })}
        >
          {formattedAddress}
        </Descriptions.Item>

        <Descriptions.Item
          label={intl.formatMessage({
            id: 'views.plans.planStore.market',
          })}
        >
          {market}
        </Descriptions.Item>

        <Descriptions.Item
          label={intl.formatMessage({
            id: 'views.plans.planStore.storeLocation',
          })}
        >
          {lat ?? TEXT_DEFAULT.NA}, {long ?? TEXT_DEFAULT.NA}
        </Descriptions.Item>

        <Descriptions.Item
          label={intl.formatMessage({
            id: 'common.table.createdAt',
          })}
        >
          {formatTime(created_at)}
        </Descriptions.Item>
      </Descriptions>
    </StyledCard>
  );
}
