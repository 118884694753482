import { useIntl } from 'react-intl';
import { StyledSelect } from 'styles/overrides';
import _ from 'lodash';
import { useProvinces } from 'api/locationApi';
import { filterOptions } from 'utils/formatUtils';

export default function ProvinceSelector({ onChange, value, multiple, ...restProps }) {
  const intl = useIntl();

  const { data = [], isLoading } = useProvinces({
    options: {
      select: (response) =>
        _.map(response, ({ id, name, prefix }) => ({
          label: `${prefix} ${name}`,
          value: id.toString(),
        })),
    },
  });

  return (
    <StyledSelect
      mode={multiple ? "multiple" : undefined}
      options={data}
      loading={isLoading}
      placeholder={intl.formatMessage({ id: 'common.placeholder.province' })}
      showSearch
      optionFilterProp="label"
      filterOption={filterOptions}
      {...restProps}
      onChange={onChange}
      value={value}
    />
  );
}
