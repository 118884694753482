import { useMemo, useState } from 'react';
import { Spin } from 'antd';
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  InfoWindow,
} from '@react-google-maps/api';
import _ from 'lodash';
import MapInfoWindow from '../MapInfoWindow/MapInfoWindow';

const DEFAULT_CENTER = {
  lat: 10.754792,
  lng: 106.6952276,
};

export default function MapBlock({
  isLoading = false,
  markers = [],
  trackings = [],
}) {
  const [selectedMarker, setSelectedMarker] = useState(null);
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
  });

  const center = useMemo(() => {
    return {
      lat: +markers?.[0]?.lat || DEFAULT_CENTER.lat,
      lng: +markers?.[0]?.long || DEFAULT_CENTER.lng,
    };
  }, [markers]);

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return (
    <Spin spinning={!isLoaded || isLoading}>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: 600 }}
          center={center}
          zoom={15}
          onClick={() => setSelectedMarker(null)}
        >
          {_.map(markers, (marker) => {
            const { lat, long, color, id } = marker;

            return (
              <Marker
                key={id}
                position={{ lat: +lat, lng: +long }}
                options={{
                  icon: {
                    path: 'M7.8,1.3L7.8,1.3C6-0.4,3.1-0.4,1.3,1.3c-1.8,1.7-1.8,4.6-0.1,6.3c0,0,0,0,0.1,0.1 l3.2,3.2l3.2-3.2C9.6,6,9.6,3.2,7.8,1.3C7.9,1.4,7.9,1.4,7.8,1.3z M4.6,5.8c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.3,1.4-1.3 c0.7,0,1.3,0.6,1.3,1.3 C5.9,5.3,5.3,5.9,4.6,5.8z',
                    fillColor: color,
                    fillOpacity: 1,
                    strokeColor: 'white',
                    strokeWeight: 1,
                    scale: 3.5,
                  },
                }}
                onClick={() => setSelectedMarker(marker)}
              />
            );
          })}

          {selectedMarker && (
            <InfoWindow
              position={{
                lat: +selectedMarker.lat,
                lng: +selectedMarker.long,
              }}
              onCloseClick={() => setSelectedMarker(null)}
            >
              <MapInfoWindow marker={selectedMarker} />
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </Spin>
  );
}
