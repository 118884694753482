import { useIntl } from 'react-intl';
import { StyledSelect } from 'styles/overrides';
import _ from 'lodash';
import { useWards, useWardsMultiple } from 'api/locationApi';
import { filterOptions } from 'utils/formatUtils';

export default function WardSelector({
  provinceId,
  districtId,
  onChange,
  value,
  multiple,
  ...restProps
}) {
  const intl = useIntl();
  const WardsMultiple = () => {
    return useWardsMultiple({
       params: {
         'province_id' : provinceId,
         'district_ids' : districtId,
       },
       options: {
         select: (response) =>
           _.map(response, ({ id, name, prefix }) => ({
             label: `${prefix} ${name}`,
             value: id.toString(),
           })),
       },
     }) 
   }
 
   const Wards = () => {
    return useWards({
     provinceId,
     districtId,
     options: {
       select: (response) =>
         _.map(response, ({ id, name, prefix }) => ({
           label: `${prefix} ${name}`,
           value: id.toString(),
         })),
     },
   })
   }
 
   let result = multiple ? WardsMultiple() : Wards()
   const { data = [], isLoading } = result

  return (
    <StyledSelect
      mode={multiple ? "multiple" : undefined}
      options={data}
      placeholder={intl.formatMessage({ id: 'common.placeholder.ward' })}
      loading={isLoading}
      {...restProps}
      showSearch
      optionFilterProp="label"
      filterOption={filterOptions}
      onChange={onChange}
      value={value}
    />
  );
}
