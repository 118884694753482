import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Form } from 'antd';
import AddEditStoreTypeForm from '../AddEditStoreTypeForm';
import { Modal } from 'components/modal/Modal';
import { useCreateStoreType, useUpdateStoreType } from 'api/storeTypeApi';
import { Notification } from 'components/Notification';
import { useResetFormOnCloseModal } from 'hooks/useResetFormOnCloseModal';

const formattedInitialValues = (data) => {
  if (!data) {
    return null;
  }

  const { name, color } = data;

  return {
    name,
    color,
  };
};

export default function AddEditStoreTypeModal({
  initialValues,
  onSuccess,
  ...props
}) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const createStoreTypeMutation = useCreateStoreType();
  const updateStoreTypeMutation = useUpdateStoreType(initialValues?.id);

  useResetFormOnCloseModal({
    form,
    open: props?.open,
  });

  const mutation = useMemo(
    () => (initialValues ? updateStoreTypeMutation : createStoreTypeMutation),
    [initialValues, updateStoreTypeMutation, createStoreTypeMutation],
  );

  const handleSubmit = (values) => {
    mutation.mutate(values, {
      onSuccess: () => {
        Notification(
          'success',
          intl.formatMessage({
            id: `message.${initialValues ? 'updateSuccess' : 'createSuccess'}`,
          }),
        );
        onSuccess();
      },
      onError: (error) => {
        Notification(
          'error',
          error.message ?? intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  return (
    <Modal
      title={intl.formatMessage({
        id: `common.modal.${initialValues ? 'editTitle' : 'addNewTitle'}`,
      })}
      onOk={() => {
        form
          .validateFields()
          .then(handleSubmit)
          .catch(() => {});
      }}
      okButtonProps={{ loading: mutation.isLoading }}
      {...props}
    >
      <AddEditStoreTypeForm
        form={form}
        initialValues={formattedInitialValues(initialValues)}
      />
    </Modal>
  );
}
