import { useEffect } from 'react';
import { Form } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import _ from 'lodash';
import {
  StyledFormList,
  StyledFormItem,
  StyledInputNumber,
} from 'styles/overrides';
import { StyledButton } from 'styles/overrides';

export default function RenderInputJsonComponent({ component, className }) {
  const formInstance = Form.useFormInstance();
  const { id, name, required } = component;
  const watchedField = Form.useWatch([id, 'content', 'floors'], formInstance);

  useEffect(() => {
    const total =
      _.reduce(
        watchedField,
        (acc, currentItem) => {
          return acc + (parseFloat(currentItem?.acreage) ?? 0);
        },
        0,
      ) ?? 0;

    formInstance.setFieldValue([id, 'content', 'total_acreage'], total);
  }, [watchedField, formInstance, id]);

  return (
    <StyledFormItem label={name} className={className}>
      <StyledFormList name={[id, 'content', 'floors']} initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <div key={key} style={{ display: 'flex' }}>
                <StyledFormItem
                  {...restField}
                  name={[name, 'acreage']}
                  rules={[{ required: !!required, message: 'Bắt buộc' }]}
                  label={`Tầng ${index + 1}`}
                  style={{ flexGrow: 1, marginRight: 24 }}
                >
                  <StyledInputNumber min={0} controls={false} />
                </StyledFormItem>

                {fields.length > 1 && (
                  <StyledButton
                    type="primary"
                    icon={<MinusCircleOutlined />}
                    onClick={() => remove(name)}
                  />
                )}
              </div>
            ))}

            <StyledFormItem style={{ textAlign: 'center' }}>
              <StyledButton type="danger" onClick={() => add()}>
                Thêm tầng
              </StyledButton>
            </StyledFormItem>
          </>
        )}
      </StyledFormList>

      <StyledFormItem
        label="Tổng diện tích (m2)"
        name={[id, 'content', 'total_acreage']}
      >
        <StyledInputNumber min={0} disabled />
      </StyledFormItem>
    </StyledFormItem>
  );
}
