import PropTypes from 'prop-types';
import styled from 'styled-components';
import LogoImg from 'assets/images/logo.png';
import vinamilkLogoImg from 'assets/images/logo_vinamilk.png';
import { StyledSpace } from 'styles/overrides';

const StyledLayout = styled.div`
  max-width: 412px;
  width: 100%;
  padding: 56px 72px;
  border-radius: 8px;
  background-color: var(--white);
  @media (max-width: 550px) {
    max-width: 350px;
    padding: 36px 42px;
  }

  > .ant-typography {
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 40px;
    color: var(--primary);
    text-align: center;
  }
  img {
    max-width: 80%;
    height: auto;
  }
`;

export const Layout = ({ children }) => {
  return (
    <StyledLayout>
      <StyledSpace
        direction="vertical"
        style={{ marginBottom: 40, textAlign: 'center' }}
      >
        <img src={vinamilkLogoImg} alt="Vinamilk" />
        {/* <img src={LogoImg} alt="logo" /> */}
      </StyledSpace>

      {children}
    </StyledLayout>
  );
};

Layout.propTypes = {
  children: PropTypes.element,
};
