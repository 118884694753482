import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { Form } from 'antd';
import { RenderBlock } from 'components/render';
import { StyledForm, StyledButton } from 'styles/overrides';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';

export default function PlanSurveyForm({
  initialValues,
  questions = [],
  groupImages,
  mappingAudios,
  onSubmit = () => {},
  onDeleteImage = () => {},
  onRotateImage = () => {},
  onShowHideQuestions = () => {},
}) {
  const intl = useIntl();
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    const convertData = _.reduce(
      values,
      (acc, value, key) => {
        acc.question_answers.push({ ...value, question_id: key });

        return acc;
      },
      { question_answers: [] },
    );

    onSubmit(convertData);
  };

  useEffect(() => {
    form.setFieldValue(['images'], groupImages);
  }, [form, groupImages]);

  useEffect(() => {
    form.setFieldsValue({ audios: mappingAudios });
  }, [form, mappingAudios]);

  useEffect(() => {
    form.setFieldsValue({
      ...initialValues,
    });
  }, [form, initialValues]);

  return (
    <StyledForm
      form={form}
      preserve={false}
      layout="horizontal"
      onFinish={handleSubmit}
      colon={false}
      labelAlign="left"
      labelWrap
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
    >
      {questions.length > 0 && (
        <>
          {_.map(questions, (block) => {
            return (
              <RenderBlock
                key={block.id}
                block={block}
                onDeleteImage={onDeleteImage}
                onRotateImage={onRotateImage}
                onShowHideQuestions={onShowHideQuestions}
              />
            );
          })}
        </>
      )}

      <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC]}>
        <div style={{ textAlign: 'center', marginTop: 24 }}>
          <StyledButton htmlType="submit" type="danger">
            {intl.formatMessage({ id: 'common.btn.save' })}
          </StyledButton>
        </div>
      </Permission>
    </StyledForm>
  );
}
