import { useState } from 'react';
import _ from 'lodash';
import { useIntl } from 'react-intl';
import { Spin, Form, Row, Col, Image, Checkbox } from 'antd';
import {
  StyledButton,
  StyledCard,
  StyledForm,
  StyledFormItem,
  StyledCardMeta,
} from 'styles/overrides';
import { useGetPlanRepairImages, useUpdatePlanRepairImages } from 'api/planApi';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';
import { formatTime } from 'utils/timeUtils';
import { useQueryClient } from 'react-query';
import { Notification } from 'components/Notification';

export default function PlanFix({ planId }) {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const [form] = Form.useForm();
  const updatePlanRepairImagesMutation = useUpdatePlanRepairImages({
    id: planId,
  });
  const {
    data = [],
    isLoading,
    refetch,
  } = useGetPlanRepairImages({
    id: planId,
    options: {
      select: (response) => response?.data ?? [],
    },
  });

  const handleConfirm = async ({ images }) => {
    if (images.length === 0) return;

    setIsLoadingSubmit(true);

    try {
      const allMutations = _.map(images, (imageId) => {
        return updatePlanRepairImagesMutation.mutateAsync({
          imageId,
          status_repair: 1,
        });
      });

      await Promise.all(allMutations);

      refetch();
      queryClient.invalidateQueries({
        queryKey: ['plans', 'details', planId, 'images'],
      });
    } catch (error) {
      Notification(
        'error',
        error.message ||
          intl.formatMessage({
            id: 'message.commonError',
          }),
      );
    }

    setIsLoadingSubmit(false);
  };

  return (
    <Spin spinning={isLoading || isLoadingSubmit}>
      <StyledForm
        form={form}
        onFinish={handleConfirm}
        initialValues={{ images: [] }}
      >
        <StyledCard
          title={intl.formatMessage({ id: 'views.plans.planFix.title' })}
          size="small"
          extra={
            <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC]}>
              <StyledFormItem
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.images !== currentValues.images
                }
              >
                {({ getFieldValue }) => {
                  const isDisabled = getFieldValue('images').length === 0;

                  return (
                    <StyledButton
                      type="danger"
                      htmlType="submit"
                      disabled={isDisabled}
                    >
                      {intl.formatMessage({ id: 'common.btn.confirm' })}
                    </StyledButton>
                  );
                }}
              </StyledFormItem>
            </Permission>
          }
        >
          <StyledFormItem name="images">
            <Checkbox.Group style={{ width: '100%' }}>
              <Row gutter={[24, 24]}>
                {_.map(data, ({ id, path, group, created_at }) => (
                  <Col span={6} key={id}>
                    <StyledCard
                      hoverable
                      cover={
                        <Image
                          alt=""
                          src={path}
                          height={250}
                          style={{ objectFit: 'cover' }}
                        />
                      }
                    >
                      <StyledCardMeta
                        title={group?.name}
                        description={formatTime(created_at)}
                      />
                      <div style={{ position: 'absolute', top: 0, right: 0 }}>
                        <Checkbox value={id} />
                      </div>
                    </StyledCard>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </StyledFormItem>
        </StyledCard>
      </StyledForm>
    </Spin>
  );
}
