import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { StyledSelect } from 'styles/overrides';
import _ from 'lodash';
import { filterOptions } from 'utils/formatUtils';

export default function QCStatusSelector({ onChange, value, ...restProps }) {
  const intl = useIntl();

  const options = useMemo(
    () =>
      _.map([0, 1], (key) => ({
        label: intl.formatMessage({ id: `common.status.qc.${key}` }),
        value: key.toString(),
      })),
    [intl],
  );

  return (
    <StyledSelect
      options={options}
      showSearch
      optionFilterProp="label"
      filterOption={filterOptions}
      {...restProps}
      onChange={onChange}
      value={value}
    />
  );
}
