import React from 'react';
import { Form } from 'antd';
import { StyledFormItem } from 'styles/overrides';
import { formatTime } from 'utils/timeUtils';

export default function RenderAudio({ id }) {
  const formInstance = Form.useFormInstance();
  const currentAudio = formInstance.getFieldValue('audios')?.[id];

  if (!currentAudio) {
    return null;
  }

  const { path, created_at } = currentAudio;
  const createdAt = formatTime(created_at);

  return (
    <StyledFormItem label={createdAt}>
      <audio controls>
        <source src={path} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </StyledFormItem>
  );
}
