import { useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import { useCreateFile } from 'api/fileApi';
import _ from 'lodash';

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const serializeFileList = (files) => {
  const mappedFiles = _.map(files, (file) => file?.response ?? null) ?? [];

  return _.filter(mappedFiles, (file) => !!file?.id);
};

const normalizeFileList = (files) =>
  _.map(files, (file) => ({
    url: file?.path,
    name: file?.name,
    uid: file?.id ? file.id.toString() : new Date().getTime(),
    response: file,
    status: 'done',
  })) ?? [];

export default function StoreUploadAvt({ value, onChange, fileMax = 1 }) {
    const createMutation = useCreateFile();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState(() => normalizeFileList(value));

    useEffect(() => {
        onChange?.(serializeFileList(fileList));
      }, [fileList]);
    
      const handleCancel = () => setPreviewOpen(false);
    
      const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
          file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(
          file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
        );
      };
    
      const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
      };
    
      const uploadButton = (
        <div>
          <PlusOutlined />
          <div
            style={{
              marginTop: 8,
            }}
          >
            Upload
          </div>
        </div>
      );
    
      const handleUploadImage = async (options) => {
        const { onSuccess, onError, file, onProgress } = options;
        const config = {
          onUploadProgress: (event) => {
            onProgress({ percent: (event.loaded / event.total) * 100 });
          },
        };
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', 'image');
        console.log(formData)
        createMutation.mutate(
          { data: formData, config },
          {
            onSuccess(response) {
              onSuccess(response?.data);
            },
            onError(error) {
              onError({ error });
            },
          },
        );
      };
    return (
        <>
          <Upload
            accept="image/*"
            customRequest={handleUploadImage}
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
          >
            {fileList.length >= fileMax ? null : uploadButton}
          </Upload>
          <Modal
            open={previewOpen}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
          >
            <img
              alt="example"
              style={{
                width: '100%',
              }}
              src={previewImage}
            />
          </Modal>
        </>
      );
}