import React from 'react';
import { Row, Col } from 'antd';
import { StyledFormItem, StyledInputNumber } from 'styles/overrides';

export default function RenderTextInputFromToComponent({ component }) {
  const { id, name, required } = component;

  return (
    <Row gutter={[24, 24]} align="middle">
      <Col span={8}>
        <StyledFormItem label={name} />
      </Col>

      <Col span={8}>
        <StyledFormItem
          label="Từ (%)"
          name={[id, 'content', 'from']}
          rules={[{ required: !!required, message: 'Bắt buộc' }]}
        >
          <StyledInputNumber min={0} controls={false} />
        </StyledFormItem>
      </Col>

      <Col span={8}>
        <StyledFormItem
          label="Đến (%)"
          name={[id, 'content', 'to']}
          rules={[{ required: !!required, message: 'Bắt buộc' }]}
        >
          <StyledInputNumber min={0} controls={false} />
        </StyledFormItem>
      </Col>
    </Row>
  );
}
