import { ContentWrapper } from 'components/layout';
import { Map } from './components/map';

export default function ViewMap() {
  return (
    <ContentWrapper title="Map">
      <Map />
    </ContentWrapper>
  );
}
