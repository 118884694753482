export const ROLE = {
  ROOT: 'root',
  ADMIN: 'admin',
  PL: 'pl',
  SUP: 'sup',
  USER: 'user',
  QC: 'qc',
  CUSTOMER: 'customer',
};

export const ROLES = ['supper_admin', 'admin', 'viewer'];
