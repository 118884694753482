import React from 'react';
import RenderInputJsonComponent from '../RenderInputJsonComponent/RenderInputJsonComponent';
import RenderTextInputFromToComponent from '../RenderTextInputFromToComponent/RenderTextInputFromToComponent';
import RenderSelectComponent from '../RenderSelectComponent/RenderSelectComponent';
import RenderRadioComponent from '../RenderRadioComponent/RenderRadioComponent';

export default function RenderComponent({
  component,
  className,
  onShowHideQuestions = () => {},
}) {
  const { id, type, is_show } = component;

  if (!is_show) {
    return null;
  }

  switch (type) {
    case 'select':
    case 'select_and_input': {
      return (
        <RenderSelectComponent
          key={id}
          component={component}
          className={className}
          onShowHideQuestions={onShowHideQuestions}
        />
      );
    }

    case 'checkbox': {
      return (
        <RenderRadioComponent
          key={id}
          component={component}
          className={className}
          onShowHideQuestions={onShowHideQuestions}
        />
      );
    }

    case 'input_json': {
      return (
        <RenderInputJsonComponent
          key={id}
          component={component}
          className={className}
        />
      );
    }

    case 'input_json_from_to': {
      return <RenderTextInputFromToComponent key={id} component={component} />;
    }

    default: {
      return null;
    }
  }
}
