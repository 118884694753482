import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Form, Row, Col } from 'antd';
import { CSS_STATUS } from 'constants/status';

import { StyledForm, StyledSpace, StyledButton } from 'styles/overrides';

const StyledFilterForm = styled.div`
  padding: 24px;
  background-color: var(--gray100);
  border: 1px solid var(--gray300);
  box-shadow: inset 0px 2px 0px rgba(231, 235, 238, 0.2);
  border-radius: 4px;
  margin-bottom: 24px;
  .ant-btn-lg {
    height: 32px !important;
    padding: 0 15px !important;
  }
  .ant-space-item {
    width:100%;
    display:${
      (props) => 
        props.cssDislayContents === `${CSS_STATUS.DISPLAY_CONTENTS}`
        ? 
          'contents' 
        : 
          'block'
    } ;
  }
`;

export const FilterForm = ({
  formInstance,
  children,
  initialValues,
  extraActions,
  cssDislayContents,
  isShowResetBtn = true,
  ...props
}) => {
  const intl = useIntl();
  const [form] = Form.useForm();
  const currentFormInstance = formInstance ?? form;

  useEffect(() => {
    currentFormInstance.setFieldsValue(initialValues);
  }, [currentFormInstance, initialValues]);

  const handleReset = () => {
    currentFormInstance.resetFields();
    currentFormInstance.submit();
  };

  return (
    <StyledFilterForm cssDislayContents={cssDislayContents} >
      <StyledSpace size={32} style={{ justifyContent: 'space-between' }}>
        <StyledForm {...props} form={currentFormInstance}>
          {children}
        </StyledForm>

        <StyledSpace size={16}>
          <StyledButton type="dark" onClick={currentFormInstance.submit}>
            {intl.formatMessage({ id: 'common.btn.search' })}
          </StyledButton>

          {isShowResetBtn && (
            <StyledButton onClick={handleReset}>
              {intl.formatMessage({ id: 'common.btn.reset' })}
            </StyledButton>
          )}

          {extraActions}
        </StyledSpace>
      </StyledSpace>
    </StyledFilterForm>
  );
};
