import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Row, Col } from 'antd';
import { StyledForm, StyledFormItem, StyledInput } from 'styles/overrides';

export default function AddEditStoreTypeForm({ form, initialValues }) {
  const intl = useIntl();

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form]);

  return (
    <StyledForm layout="vertical" size="large" form={form}>
      <Row gutter={[32, 0]}>
        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.storeTypes.form.label.name',
            })}
            name="name"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.storeTypes.form.label.color',
            })}
            name="color"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>
      </Row>
    </StyledForm>
  );
}
