import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Avatar, Typography, Modal } from 'antd';
import {
  calcIndexByOrder,
  removeFalsyKeys,
  calcCurrentPageWithNum,
} from 'utils/common';
import { useCustomSearchParams } from 'hooks/useCustomSearchParams';
import { Table } from 'components/table';
import { formatTime } from 'utils/timeUtils';
import { StyledActions, StyledButton, StyledSpace } from 'styles/overrides';
import PlanFilterSearch from '../PlanFilterSearch/PlanFilterSearch';
import { formatAddress } from 'utils/formatUtils';
import { usePlans, useDeleteMultiplePlans } from 'api/planApi';
import { Notification } from 'components/Notification';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';
import { CSS_STATUS } from 'constants/status';

export default function PlanTable() {
  const intl = useIntl();
  const [search, setSearch] = useCustomSearchParams();
  const { data, isLoading, refetch } = usePlans({ params: search });
  const deleteMultiplePlansMutation = useDeleteMultiplePlans();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const columns = [
    {
      title: intl.formatMessage({ id: 'common.table.no' }),
      render: (text, record, index) => {
        return calcIndexByOrder({
          index,
          page: search.page,
          limit: search.page_size,
        });
      },
    },
    {
      title: intl.formatMessage({ id: 'views.plans.table.name' }),
      dataIndex: 'name',
    },
    {
      title: intl.formatMessage({ id: 'views.plans.table.storeInfo' }),
      render: ({ store }) => {
        const {
          store_name,
          code,
          phone,
          image,
          address,
          street,
          ward,
          district,
          province,
        } = store;

        return (
          <StyledSpace direction="vertical">
            <Avatar src={image?.path} size={90} shape="square" />

            <div>
              <Typography.Title level={5} style={{ marginBottom: 0 }}>
                {store_name}
              </Typography.Title>
              <Typography.Paragraph type="danger" style={{ marginBottom: 0 }}>
                {code}
              </Typography.Paragraph>
              <Typography.Paragraph style={{ marginBottom: 0 }}>
                {phone}
              </Typography.Paragraph>
              <Typography.Paragraph style={{ marginBottom: 0 }}>
                {formatAddress({
                  address,
                  street,
                  ward: ward?.name,
                  district: district?.name,
                  province: province?.name,
                })}
              </Typography.Paragraph>
            </div>
          </StyledSpace>
        );
      },
    },
    {
      title: intl.formatMessage({ id: 'views.plans.table.userInfo' }),
      dataIndex: ['user', 'full_name'],
    },
    {
      title: intl.formatMessage({ id: 'views.plans.table.userQC' }),
      dataIndex: ['qc', 'full_name'],
    },
    {
      title: intl.formatMessage({ id: 'common.table.storeStatus' }),
      dataIndex: ['store_status', 'name'],
    },
    {
      title: intl.formatMessage({ id: 'common.table.status' }),
      dataIndex: 'status',
      render: (value) =>
        intl.formatMessage({ id: `common.status.plan.${value}` }),
    },
    {
      title: 'QC',
      dataIndex: ['qc_status'],
      render: (value) =>
        intl.formatMessage({ id: `common.status.qc.${value}` }),
    },
    {
      title: intl.formatMessage({ id: 'common.table.createdAt' }),
      dataIndex: 'created_at',
      render: (value) => formatTime(value),
    },
    {
      dataIndex: 'id',
      render: (id) => (
        <Link to={`${id}`}>
          <StyledButton type="primary">Chi tiết</StyledButton>
        </Link>
      ),
    },
  ];

  const handleChangeTable = ({ current: page, pageSize: limit }) => {
    setSearch(removeFalsyKeys({ ...search, page, page_size: limit }));
  };

  const handleDelete = () => {
    if (selectedRowKeys.length <= 0) return;

    Modal.confirm({
      title: intl.formatMessage({ id: 'common.modal.deleteTitle' }),
      okText: intl.formatMessage({ id: 'common.btn.confirm' }),
      onOk() {
        return deleteMultiplePlansMutation.mutateAsync(selectedRowKeys, {
          onSuccess() {
            const calculatedPage = calcCurrentPageWithNum({
              page: search.page,
              limit: search.page_size,
              total: data?.meta?.total,
              num: selectedRowKeys.length,
            });

            if (calculatedPage !== search.page) {
              setSearch(
                removeFalsyKeys({
                  ...search,
                  page: calculatedPage,
                }),
              );
            } else {
              refetch();
            }

            setSelectedRowKeys([]);
            Notification(
              'success',
              intl.formatMessage({ id: 'message.deleteSuccess' }),
            );
          },
          onError(error) {
            Notification(
              'error',
              error?.message ||
                intl.formatMessage({ id: 'message.commonError' }),
            );
          },
        });
      },
      onCancel() {},
    });
  };

  return (
    <>
      <PlanFilterSearch
        onSubmit={(values) => setSearch(values)}
        initialValues={search}
        cssDislayContents={CSS_STATUS.DISPLAY_CONTENTS}
      />

      <Permission roles={[ROLE.PL]}>
        <StyledActions size={24}>
          <StyledButton
            onClick={handleDelete}
            type="danger"
            disabled={selectedRowKeys.length <= 0}
          >
            {intl.formatMessage({ id: 'common.btn.delete' })}
          </StyledButton>
        </StyledActions>
      </Permission>

      <Table
        rowKey="id"
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys) => {
            setSelectedRowKeys(newSelectedRowKeys);
          },
          preserveSelectedRowKeys: true,
        }}
        columns={columns}
        dataSource={data?.data || []}
        loading={isLoading}
        pagination={{
          current: search?.page,
          pageSize: search?.page_size,
          total: data?.meta?.total || 0,
        }}
        onChange={handleChangeTable}
      />
    </>
  );
}
