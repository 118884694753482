import _ from 'lodash';
import { Spin, Typography, Modal } from 'antd';
import { useIntl } from 'react-intl';
import {
  StyledCard,
  StyledSpace,
  StyledDescriptions,
  StyledDescriptionsItem,
  StyledButton,
} from 'styles/overrides';
import {
  useGetNotesInGroups,
  useCreateNote,
  useDeleteRepair,
  useConfirmRepair,
  useDeleteNote,
} from 'api/planApi';
import { formatTime } from 'utils/timeUtils';
import PlanNoteForm from '../PlanNoteForm/PlanNoteForm';
import { Notification } from 'components/Notification';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';

export default function PlanNote({ planId }) {
  const intl = useIntl();
  const createNoteMutation = useCreateNote({ id: planId });
  const deleteRepairMutation = useDeleteRepair({ planId });
  const confirmRepairMutation = useConfirmRepair({ id: planId });
  const deleteNoteMutation = useDeleteNote({ planId });

  const {
    data = [],
    isLoading,
    isFetching,
    refetch,
  } = useGetNotesInGroups({
    id: planId,
    options: {
      select: (response) => response?.data ?? [],
    },
  });

  const handleSubmitNote = (values) => {
    createNoteMutation.mutate(values, {
      onSuccess() {
        refetch();
      },
      onError(error) {
        Notification(
          'error',
          error?.message || intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  const handleConfirmRepair = (repairId) => {
    Modal.confirm({
      title: intl.formatMessage({ id: 'common.modal.confirmRepairTitle' }),
      okText: intl.formatMessage({ id: 'common.btn.confirm' }),
      onOk() {
        return confirmRepairMutation.mutateAsync(
          { repairId, is_confirm: 1 },
          {
            onSuccess() {
              refetch();
              Notification(
                'success',
                intl.formatMessage({ id: 'message.commonSuccess' }),
              );
            },
            onError(error) {
              Notification(
                'error',
                error?.message ||
                  intl.formatMessage({ id: 'message.commonError' }),
              );
            },
          },
        );
      },
      onCancel() {},
    });
  };

  const handleDeleteRepair = (id) => {
    Modal.confirm({
      title: intl.formatMessage({ id: 'common.modal.deleteTitle' }),
      okText: intl.formatMessage({ id: 'common.btn.confirm' }),
      onOk() {
        return deleteRepairMutation.mutateAsync(id, {
          onSuccess() {
            refetch();
          },
          onError(error) {
            Notification(
              'error',
              error.message ||
                intl.formatMessage({
                  id: 'message.commonError',
                }),
            );
          },
        });
      },
      onCancel() {},
    });
  };

  const handleDeleteNote = (id) => {
    Modal.confirm({
      title: intl.formatMessage({ id: 'common.modal.deleteTitle' }),
      okText: intl.formatMessage({ id: 'common.btn.confirm' }),
      onOk() {
        return deleteNoteMutation.mutateAsync(id, {
          onSuccess() {
            refetch();
          },
          onError(error) {
            Notification(
              'error',
              error.message ||
                intl.formatMessage({
                  id: 'message.commonError',
                }),
            );
          },
        });
      },
      onCancel() {},
    });
  };

  return (
    <Spin
      spinning={
        isLoading ||
        isFetching ||
        createNoteMutation.isLoading ||
        confirmRepairMutation.isLoading
      }
    >
      <StyledCard
        title={intl.formatMessage({ id: 'views.plans.planNote.title' })}
        size="small"
        extra={<PlanNoteForm onSubmit={handleSubmitNote} />}
      >
        <StyledSpace direction="vertical" size={16}>
          {_.map(data, ({ id, name, type, notes, repairs }) => {
            const dataSource = type === 'repair' ? repairs : notes;
            const isRepair = type === 'repair';

            return (
              <StyledDescriptions
                key={id}
                title={name}
                column={1}
                bordered
                size="small"
              >
                {_.map(
                  dataSource,
                  ({
                    id,
                    content,
                    user,
                    confirm_at,
                    is_confirm,
                    updated_at,
                    display_delete,
                  }) => {
                    const userName = user?.full_name ?? user?.name;

                    return (
                      <StyledDescriptionsItem label={content} key={id}>
                        <StyledSpace direction="vertical">
                          <Typography.Text>
                            {userName} - {formatTime(updated_at)}
                          </Typography.Text>

                          {!isRepair && (
                            <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC]}>
                              <StyledButton
                                type="danger"
                                onClick={() => handleDeleteNote(id)}
                              >
                                {intl.formatMessage({
                                  id: 'common.btn.delete',
                                })}
                              </StyledButton>
                            </Permission>
                          )}

                          {isRepair && (
                            <>
                              {!!is_confirm && (
                                <Typography.Text>
                                  <Typography.Text strong>
                                    Thời gian xác nhận
                                  </Typography.Text>
                                  :{' '}
                                  <Typography.Text type="danger">
                                    {formatTime(confirm_at)}
                                  </Typography.Text>
                                </Typography.Text>
                              )}

                              {!is_confirm && (
                                <Permission
                                  roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC]}
                                >
                                  <StyledSpace>
                                    <StyledButton
                                      type="primary"
                                      onClick={() => handleConfirmRepair(id)}
                                    >
                                      {intl.formatMessage({
                                        id: 'common.btn.confirmRepaired',
                                      })}
                                    </StyledButton>

                                    {!!display_delete && (
                                      <StyledButton
                                        type="danger"
                                        onClick={() => handleDeleteRepair(id)}
                                      >
                                        {intl.formatMessage({
                                          id: 'common.btn.delete',
                                        })}
                                      </StyledButton>
                                    )}
                                  </StyledSpace>
                                </Permission>
                              )}
                            </>
                          )}
                        </StyledSpace>
                      </StyledDescriptionsItem>
                    );
                  },
                )}
              </StyledDescriptions>
            );
          })}
        </StyledSpace>
      </StyledCard>
    </Spin>
  );
}
