import _ from 'lodash';
import { Typography, Spin } from 'antd';
import styled from 'styled-components';
import { MarkerIcon } from 'assets/icons';
import { StyledSpace } from 'styles/overrides';

const StyledMapInfo = styled(StyledSpace)`
  display: flex;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.3);
  margin-bottom: 24px;
  justify-content: flex-end;
`;

export default function MapInfo({ infos = [], isLoading = false }) {
  return (
    <Spin spinning={isLoading}>
      {infos.length > 0 && (
        <div style={{ textAlign: 'center' }}>
          <StyledMapInfo direction="vertical">
            {_.map(infos, ({ color, name, total }) => (
              <StyledSpace key={name} align="center">
                <MarkerIcon style={{ color, fontSize: 20 }} />
                <Typography.Text style={{ color }}>
                  {name} {total && `(${total})`}
                </Typography.Text>
              </StyledSpace>
            ))}
          </StyledMapInfo>
        </div>
      )}
    </Spin>
  );
}
