import { useIntl } from 'react-intl';
import { StyledButton } from 'styles/overrides';
import { DownloadIcon } from 'assets/icons';

export const DownloadTemplate = ({ mutation, title }) => {
  const intl = useIntl();

  const handleClick = async () => {
    if (!mutation) {
      return;
    }

    mutation.mutate(null, {
      onSuccess(res) {
        if (res?.path) {
          window.location.href = res.path;
        }
      },
    });
  };

  return (
    <StyledButton
      icon={<DownloadIcon />}
      onClick={handleClick}
      loading={mutation.isLoading}
    >
      {title ?? intl.formatMessage({ id: 'common.btn.downloadCsvTemplate' })}
    </StyledButton>
  );
};
