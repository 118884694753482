import { useIntl } from 'react-intl';
import { Form } from 'antd';
import { Modal } from 'components/modal/Modal';
import { useResetFormOnCloseModal } from 'hooks/useResetFormOnCloseModal';
import StoreUploadImageForm from '../StoreUploadImageForm/StoreUploadImageForm';

export default function StoreUploadImageModal({
  isLoading = false,
  onSubmit = () => {},
  ...props
}) {
  const intl = useIntl();
  const [form] = Form.useForm();

  useResetFormOnCloseModal({
    form,
    open: props?.open,
  });

  return (
    <Modal
      title={intl.formatMessage({ id: 'common.modal.addNewTitle' })}
      onOk={() => {
        form
          .validateFields()
          .then(onSubmit)
          .catch(() => {});
      }}
      okButtonProps={{ loading: isLoading }}
      {...props}
    >
      <StoreUploadImageForm form={form} />
    </Modal>
  );
}
