import { useIntl } from 'react-intl';
import { Form, Row, Col } from 'antd';
import { DEFAULT_PAGINATION, MULTIPLE_SELECT } from 'constants/common';
import { FilterForm } from 'components/form';
import { StyledFormItem } from 'styles/overrides';
import {
  ProvinceSelector,
  DistrictSelector,
  WardSelector,
  StoreTypeSelector,
} from 'components/selectors';
import { normalizeSearchData, normalizeSubmitSearchData } from 'utils/common';

export default function MapFilterSearch({ onSubmit, initialValues }) {
  const intl = useIntl();
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    onSubmit(
      normalizeSubmitSearchData({
        ...initialValues,
        ...values,
        page: DEFAULT_PAGINATION.PAGE,
      }),
    );
  };

  const handleValuesChange = (changedValues) => {
    if (changedValues['province_id']) {
      form.setFieldsValue({
        district_id: undefined,
        ward_id: undefined,
      });
    }

    if (changedValues['district_id']) {
      form.setFieldsValue({
        ward_id: undefined,
      });
    }
  };

  const handleClear = (name) => {
    if (name === 'province_id') {
      form.setFieldsValue({
        district_id: undefined,
        ward_id: undefined,
      });
    }

    if (name === 'district_id') {
      form.setFieldsValue({
        ward_id: undefined,
      });
    }
  };

  return (
    <FilterForm
      onFinish={handleSubmit}
      initialValues={normalizeSearchData(initialValues)}
      formInstance={form}
      onValuesChange={handleValuesChange}
      preserve={false}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <StyledFormItem name="province_id">
            <ProvinceSelector
              multiple={MULTIPLE_SELECT}
              allowClear
              onClear={() => handleClear('province_id')}
            />
          </StyledFormItem>
        </Col>

        <Col span={24}>
          <StyledFormItem
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.province_id !== currentValues.province_id
            }
          >
            {({ getFieldValue }) => {
              const provinceId = getFieldValue('province_id');

              return (
                <StyledFormItem name="district_id">
                  <DistrictSelector
                    multiple={MULTIPLE_SELECT}
                    provinceId={provinceId}
                    // $customType="filter"
                    allowClear
                    onClear={() => handleClear('district_id')}
                  />
                </StyledFormItem>
              );
            }}
          </StyledFormItem>
        </Col>

        <Col span={24}>
          <StyledFormItem
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.province_id !== currentValues.province_id ||
              prevValues.district_id !== currentValues.district_id
            }
          >
            {({ getFieldValue }) => {
              const provinceId = getFieldValue('province_id');
              const districtId = getFieldValue('district_id');

              return (
                <StyledFormItem name="ward_id">
                  <WardSelector
                    multiple={MULTIPLE_SELECT}
                    provinceId={provinceId}
                    districtId={districtId}
                    allowClear
                  />
                </StyledFormItem>
              );
            }}
          </StyledFormItem>
        </Col>
        <Col span={24}>
          <StyledFormItem noStyle>
            <StyledFormItem name="type_code">
              <StoreTypeSelector
                multiple={MULTIPLE_SELECT}
                allowClear
              />
            </StyledFormItem>
          </StyledFormItem>
        </Col>
      </Row>
    </FilterForm>
  );
}
