import { Select } from 'antd';
import _ from 'lodash';
import { StyledSelect } from 'styles/overrides';
import { useFileGroups } from 'api/fileApi';

export default function FileGroupSelector({ onChange, value, ...restProps }) {
  const { data = [], isLoading } = useFileGroups({
    params: {
      type: 'plan',
    },
    options: {
      select: (response) => response?.data ?? [],
    },
  });

  return (
    <StyledSelect
      {...restProps}
      loading={isLoading}
      onChange={onChange}
      value={value}
    >
      {data.length > 0 &&
        _.map(data, ({ id, name }) => (
          <Select.Option key={id} value={id}>
            {name}
          </Select.Option>
        ))}
    </StyledSelect>
  );
}
