import React from 'react';
import { useIntl } from 'react-intl';
import { StyledCard } from 'styles/overrides';
import PlanQCForm from '../PlanQCForm/PlanQCForm';
import { useQCs } from 'api/publicApi';
import { useGetPlanQC, useCreatePlanQC } from 'api/planApi';
import { Notification } from 'components/Notification';
import { Spin } from 'antd';

export default function PlanQC({ planId }) {
  const intl = useIntl();
  const createPlanQC = useCreatePlanQC(planId);
  const { data: dataQC, isLoading } = useGetPlanQC({
    id: planId,
    options: {
      select: (response) => response?.data,
    },
  });

  const { data: qcs = [] } = useQCs({
    options: {
      select: (response) => response?.data ?? [],
    },
  });

  const handleSubmitQC = (data) => {
    createPlanQC.mutate(data, {
      onSuccess() {
        Notification(
          'success',
          intl.formatMessage({ id: 'message.qcSuccess' }),
        );
      },
      onError(error) {
        Notification(
          'error',
          error?.message || intl.formatMessage({ id: 'message.commonError' }),
        );
      },
    });
  };

  return (
    <Spin spinning={createPlanQC.isLoading || isLoading}>
      <StyledCard
        title={intl.formatMessage({ id: 'views.plans.planQC.title' })}
        size="small"
      >
        {!isLoading && (
          <PlanQCForm
            qcs={qcs}
            onSubmit={handleSubmitQC}
            initialValues={dataQC}
          />
        )}
      </StyledCard>
    </Spin>
  );
}
