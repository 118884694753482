import { useState } from 'react';
import { useIntl } from 'react-intl';
import _ from 'lodash';
import { StyledButton } from 'styles/overrides';
import { Notification } from 'components/Notification';
import { useCreateFile } from 'api/fileApi';
import StoreUploadImageModal from '../StoreUploadImageModal';

export default function StoreUploadImage({ storeId, onSuccess = () => {} }) {
  const intl = useIntl();
  const createFileMutation = useCreateFile();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleToggleModal = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  const handleSubmit = async ({ files }) => {
    setIsLoading(true);

    try {
      const filesWithMutation = _.map(files, (file) => {
        const formData = new FormData();

        formData.append('store_id', storeId);
        formData.append('type', 'image');
        formData.append('file', file.originFileObj);

        return createFileMutation.mutateAsync({ data: formData });
      });

      await Promise.all(filesWithMutation);

      Notification(
        'success',
        intl.formatMessage({
          id: 'message.createSuccess',
        }),
      );

      handleToggleModal();
      onSuccess();
    } catch (error) {
      Notification(
        'error',
        error.message ?? intl.formatMessage({ id: 'message.commonError' }),
      );
    }

    setIsLoading(false);
  };

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <StyledButton type="danger" onClick={handleToggleModal}>
          {intl.formatMessage({ id: 'common.btn.addNewImage' })}
        </StyledButton>
      </div>

      <StoreUploadImageModal
        open={isOpen}
        onCancel={handleToggleModal}
        onSubmit={handleSubmit}
        isLoading={isLoading}
      />
    </>
  );
}
