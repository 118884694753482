import { useIntl } from 'react-intl';
import { useUsers } from 'api/userApi';
import { StyledSelect } from 'styles/overrides';
import _ from 'lodash';
import { filterOptions } from 'utils/formatUtils';

export default function UserSelector({
  onChange,
  value,
  params = {},
  ...restProps
}) {
  const intl = useIntl();
  const { data = [], isLoading } = useUsers({
    params,
    options: {
      select: (response) =>
        _.map(response?.data, ({ id, full_name, name }) => ({
          label: `${full_name} - ${name}`,
          value: id.toString(),
        })) ?? [],
    },
  });

  return (
    <StyledSelect
      options={data}
      loading={isLoading}
      placeholder={intl.formatMessage({ id: 'common.placeholder.userId' })}
      showSearch
      optionFilterProp="label"
      filterOption={filterOptions}
      {...restProps}
      onChange={onChange}
      value={value}
    />
  );
}
