import { useIntl } from 'react-intl';
import { StyledSelect } from 'styles/overrides';
import _ from 'lodash';
import { useDistricts, useDistrictsMultiple } from 'api/locationApi';
import { filterOptions } from 'utils/formatUtils';

export default function DistrictSelector({
  provinceId,
  onChange,
  value,
  multiple,
  ...restProps
}) {
  const intl = useIntl();

  const DistrictsMultiple = () => {
    return useDistrictsMultiple({
      params: {
        'province_id': provinceId,
      },
      options: {
        select: (response) =>
          _.map(response, ({ id, name, prefix }) => ({
            label: `${prefix} ${name}`,
            value: id.toString(),
          })),
      },
    })
  }

  const Districts = () => {
    return useDistricts({
      provinceId,
      options: {
        select: (response) =>
          _.map(response, ({ id, name, prefix }) => ({
            label: `${prefix} ${name}`,
            value: id.toString(),
          })),
      },
    })
  }

  let result = multiple ? DistrictsMultiple() : Districts()
  const { data = [], isLoading } = result

  return (
    <StyledSelect
      mode={multiple ? "multiple" : undefined}
      options={data}
      loading={isLoading}
      placeholder={intl.formatMessage({ id: 'common.placeholder.district' })}
      showSearch
      optionFilterProp="label"
      filterOption={filterOptions}
      {...restProps}
      onChange={onChange}
      value={value}
    />
  );
}
