import { useIntl } from 'react-intl';
import { StyledSelect } from 'styles/overrides';
import _ from 'lodash';
import { useGetStoreType } from 'api/storeApi';
import { filterOptions } from 'utils/formatUtils';

export default function StoreTypeSelector({ onChange, multiple, value, ...restProps }) {
  const intl = useIntl();

  const { data = [], isLoading } = useGetStoreType({
    options: {
      select: (response) => {
        return _.map(response, ({ code, name }) => ({
          label: name,
          value: code.toString(),
        }));
      },
    },
  });
  return (
    <StyledSelect
      mode={multiple ? "multiple" : undefined}
      options={data}
      loading={isLoading}
      placeholder={intl.formatMessage({ id: 'common.placeholder.types' })}
      showSearch
      optionFilterProp="label"
      filterOption={filterOptions}
      {...restProps}
      onChange={onChange}
      value={value}
    />
  );
}
