import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Spin } from 'antd';

import { StyledCard } from 'styles/overrides';
import { useCreateStore } from 'api/storeApi';
import { PATH_NAME } from 'constants/routes';


import StoreForm, { getInitialValuesStoreForm } from './components/StoreForm';
import { Notification } from 'components/Notification';
import { ContentWrapper } from 'components/layout'

export default function AddStore() {
  const intl = useIntl();
  const createMutation = useCreateStore()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)

  const handleAddStore = async (values) => {
    setIsLoading(true)

    const dataResquest = {
      ...values,
      files: [values?.files[0].id]
    }

    await createMutation.mutate(dataResquest, {
      onSuccess(res) {
        Notification(
          'success',
          intl.formatMessage({
            id: `message.createSuccess`,
          }),
        );
        setIsLoading(false)
        navigate(`/${PATH_NAME.STORES}/${res.id}`);
      },
      onError(error) {
        Notification(
          'error',
          error.message ?? intl.formatMessage({ id: 'message.commonError' })
        );
        setIsLoading(false)
      },
    })
  }

  return (
    <Spin spinning={isLoading}>
      <ContentWrapper title={'Thêm mới'}>
        <StyledCard
          title={intl.formatMessage({ id: 'views.stores.title.info' })}
          size="small"
        >
          <StoreForm
            initialValues={getInitialValuesStoreForm()}
            onSubmit={handleAddStore}
            type={'addStore'}
          />
        </StyledCard>
      </ContentWrapper>
    </Spin>
  )
}