import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Row, Col, DatePicker } from 'antd';
import {
  StyledButton,
  StyledForm,
  StyledFormItem,
  StyledInput,
  StyledSwitch,
} from 'styles/overrides';
import {
  ProvinceSelector,
  DistrictSelector,
  WardSelector,
  StoreTypeSelector,
} from 'components/selectors';
import { Permission } from 'components/permission/Permission';
import { ROLE } from 'constants/roles';
import MapModal from 'views/dashboard/components/map/MapModal'
import { DATE_FORMAT } from 'constants/common';
import moment from 'moment';
import { VNMobilePhonePattern } from 'utils/validationUtils';
import StoreUploadAvt from '../StoreUploadAvt';

export const getInitialValuesStoreForm = (data) => {
  if (!data) return null;
  const {
    store_name,
    code,
    region_code,
    phone,
    province,
    district,
    ward,
    address,
    status,
    type,
    grand_opening,
    lat,
    long
  } = data;

  return {
    code,
    region_code,
    store_name,
    phone,
    province_id: province?.id?.toString(),
    district_id: district?.id?.toString(),
    ward_id: ward?.id?.toString(),
    address,
    status: !!status,
    type: type?.code?.toString(),
    grand_opening: grand_opening ? moment(grand_opening, 'YYYY-MM-DD') : null,
    lat,
    long
  };
};

export default function StoreForm({ initialValues, onSubmit = () => {}, type }) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const handleValuesChange = (changedValues) => {
    if (changedValues['province_id']) {
      form.setFieldsValue({
        district_id: null,
        ward_id: null,
      });
    }

    if (changedValues['district_id']) {
      form.setFieldsValue({
        ward_id: null,
      });
    }
  };

  const handleFinish = (values) => {
    const { status, grand_opening, ...restValues } = values;

    const data = {
      ...restValues,
      status: +status,
      grand_opening: grand_opening?.format('YYYY-MM-DD') ?? null,
    };

    onSubmit(data);
  };

  return (
    <StyledForm
      layout="vertical"
      onValuesChange={handleValuesChange}
      form={form}
      initialValues={initialValues}
      onFinish={handleFinish}
    >
      <Row gutter={[16, 0]}>
       {type && 
          <Col span={24} style={{ textAlign: 'center', marginBottom:20 }}>
            <StyledFormItem
              label={intl.formatMessage({
                id: 'common.btn.addNewImage',
              })}
              name="files"
              rules={[{ required: true }]}
            >
              <StoreUploadAvt />
            </StyledFormItem>
          </Col>
        }

        <Col span={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.stores.form.label.storeCode',
            })}
            name="code"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.stores.form.label.regionCode',
            })}
            name="region_code"
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.stores.form.label.storeName',
            })}
            name="store_name"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({ id: 'views.stores.form.label.phone' })}
            name="phone"
            rules={[
              { required: false },
              {
                pattern: VNMobilePhonePattern,
                message: intl.formatMessage({
                  id: 'validate.invalidPhoneNumber',
                }),
              },
            ]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.stores.form.label.openingDay',
            })}
            name="grand_opening"
            rules={[{ required: false }]}
          >
            <DatePicker format={DATE_FORMAT} style={{ width: '100%' }} />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.stores.form.label.provinceId',
            })}
            name="province_id"
            rules={[{ required: true }]}
          >
            <ProvinceSelector />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.province_id !== currentValues.province_id
            }
          >
            {({ getFieldValue }) => {
              const provinceId = getFieldValue('province_id');

              return (
                <StyledFormItem
                  label={intl.formatMessage({
                    id: 'views.stores.form.label.districtId',
                  })}
                  name="district_id"
                  rules={[{ required: true }]}
                >
                  <DistrictSelector provinceId={provinceId} />
                </StyledFormItem>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={24} sm={12}>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              prevValues.province_id !== currentValues.province_id ||
              prevValues.district_id !== currentValues.district_id
            }
          >
            {({ getFieldValue }) => {
              const provinceId = getFieldValue('province_id');
              const districtId = getFieldValue('district_id');

              return (
                <StyledFormItem
                  label={intl.formatMessage({
                    id: 'views.stores.form.label.wardId',
                  })}
                  name="ward_id"
                  rules={[{ required: true }]}
                >
                  <WardSelector
                    provinceId={provinceId}
                    districtId={districtId}
                  />
                </StyledFormItem>
              );
            }}
          </Form.Item>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.stores.form.label.address',
            })}
            name="address"
            rules={[{ required: true }]}
          >
            <StyledInput />
          </StyledFormItem>
        </Col>


        <Col span={12}>
          <StyledFormItem
              label={intl.formatMessage({
                id: 'views.stores.form.label.lat',
              })}
              name="lat"
            >
            <StyledInput />
            </StyledFormItem>
        </Col>

        <Col span={12}>
          <StyledFormItem
              label={intl.formatMessage({
                id: 'views.stores.form.label.long',
              })}
              name="long"
            >
            <StyledInput />
            </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.stores.form.label.status',
            })}
            name="status"
            rules={[{ required: true }]}
            valuePropName="checked"
          >
            <StyledSwitch />
          </StyledFormItem>
        </Col>

        <Col span={24} sm={12}>
          <StyledFormItem
            label={intl.formatMessage({
              id: 'views.stores.form.label.type',
            })}
            name="type"
            rules={[{ required: true }]}
          >
            <StoreTypeSelector />
          </StyledFormItem>
        </Col>

        <Permission roles={[ROLE.ADMIN, ROLE.PL, ROLE.QC]}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <StyledButton htmlType="submit" type="danger">
              {intl.formatMessage({ id: 'common.btn.save' })}
            </StyledButton>
          </Col>
        </Permission>
      </Row>
    </StyledForm>
  );
}
