import _ from 'lodash';
import { useGetNoteGroups } from 'api/planApi';
import { StyledSelect } from 'styles/overrides';
import { filterOptions } from 'utils/formatUtils';

export default function NoteGroupSelector({ onChange, value, ...restProps }) {
  const { data = [], isLoading } = useGetNoteGroups({
    options: {
      select: (response) =>
        _.map(response?.data, ({ id, name, type }) => ({
          label: name,
          value: JSON.stringify({ group_id: id, type }),
        })) ?? [],
    },
  });

  return (
    <StyledSelect
      options={data}
      loading={isLoading}
      showSearch
      optionFilterProp="label"
      filterOption={filterOptions}
      {...restProps}
      onChange={onChange}
      value={value}
    />
  );
}
