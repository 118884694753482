import _ from 'lodash';
import moment from 'moment';
import {
  FROM_TO_DATE_SUBMIT_FORMAT_TO_SERVER,
  MONTH_FORMAT_TO_SERVER,
} from 'constants/common';

export const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    return null;
  }
};

export const serializeSearchParams = (params) => {
  return Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value),
  );
};

// (page - 1) * limit + index + 1,
// total - index - (page - 1) * limit
export const calcIndexByOrder = (
  { total, index, page, limit },
  type = 'ASC',
) => {
  return type === 'ASC'
    ? (page - 1) * limit + index + 1
    : total - index - (page - 1) * limit;
};

export const calcCurrentPageWithNum = ({
  page = 1,
  limit = 10,
  total = 0,
  num = 1,
}) => {
  const calculatedPage = Math.ceil((total - num) / limit) || 1;

  return calculatedPage >= page ? page : calculatedPage;
};

export const formatterCurrency = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const removeFalsyKeys = (object, optionalFunc) => {
  return _.pickBy(object, optionalFunc);
};

export const normalizeSubmitSearchData = ({
  fromToDate,
  month,
  date,
  ...data
}) => {
  return removeFalsyKeys({
    ...data,
    from_day: fromToDate?.[0]?.format(FROM_TO_DATE_SUBMIT_FORMAT_TO_SERVER),
    to_day: fromToDate?.[1]?.format(FROM_TO_DATE_SUBMIT_FORMAT_TO_SERVER),
    month: month?.format(MONTH_FORMAT_TO_SERVER),
    date: date?.format(FROM_TO_DATE_SUBMIT_FORMAT_TO_SERVER),
  });
};

export const normalizeSearchData = ({
  from_day,
  to_day,
  month,
  date,
  ...data
}) => {
  const fromDate = from_day
    ? moment(from_day, FROM_TO_DATE_SUBMIT_FORMAT_TO_SERVER)
    : null;
  const toDate = to_day
    ? moment(to_day, FROM_TO_DATE_SUBMIT_FORMAT_TO_SERVER)
    : null;
  const fromToDate = [fromDate, toDate];

  return {
    ...data,
    fromToDate,
    month: month ? moment(month, MONTH_FORMAT_TO_SERVER) : null,
    date: date ? moment(date, FROM_TO_DATE_SUBMIT_FORMAT_TO_SERVER) : null,
  };
};

export const getFirstTitleFromBreadcrumbs = (breadcrumbs) => {
  return _.get(breadcrumbs, [0, 'label']);
};
