import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Tag, Typography, Image } from 'antd';
import { StyledSpace } from 'styles/overrides';
import { PATH_NAME } from 'constants/routes';

const StyledMapInfoWindow = styled.div`
  max-width: 250px;
  width: 100%;
`;

export default function MapInfoWindow({ marker }) {
  const { color, code, path, store_name, address_full, id } = marker;

  return (
    <StyledMapInfoWindow>
      <StyledSpace direction="vertical" size={4}>
        <Link
          to={`/${PATH_NAME.STORES}/${id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            {store_name}
          </Typography.Title>
        </Link>

        <Tag color={color}>{code}</Tag>

        <Image
          src={path}
          alt=""
          preview={false}
          style={{ objectFit: 'cover' }}
          width="100%"
          height={150}
        />

        <Typography.Paragraph>{address_full}</Typography.Paragraph>
      </StyledSpace>
    </StyledMapInfoWindow>
  );
}
