import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Avatar, Modal, Typography } from 'antd';
import { Table } from 'components/table';
import { formatTime } from 'utils/timeUtils';
import { useStoreTypes, useDeleteMultipleStoreTypes } from 'api/storeTypeApi';
import { StyledActions, StyledButton, StyledSpace } from 'styles/overrides';
import { Notification } from 'components/Notification';
import AddEditStoreTypeModal from '../AddEditStoreTypeModal';

export default function StoreTypeTable() {
  const intl = useIntl();
  const { data, isLoading, isFetching, refetch } = useStoreTypes();
  const deleteMultipleStoreTypesMutation = useDeleteMultipleStoreTypes();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);

  const columns = [
    {
      title: intl.formatMessage({ id: 'common.table.no' }),
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: intl.formatMessage({ id: 'views.storeTypes.table.name' }),
      dataIndex: ['name'],
    },
    {
      title: intl.formatMessage({ id: 'views.storeTypes.table.color' }),
      dataIndex: ['color'],
      render: (value) => (
        <StyledSpace>
          <Avatar style={{ backgroundColor: value }} size={40} shape="square" />
          <Typography.Text>{value}</Typography.Text>
        </StyledSpace>
      ),
    },
    {
      title: intl.formatMessage({ id: 'common.table.createdAt' }),
      dataIndex: 'created_at',
      render: (value) => formatTime(value),
    },
  ];

  const handleDelete = () => {
    if (selectedRowKeys.length <= 0) return;

    Modal.confirm({
      title: intl.formatMessage({ id: 'common.modal.deleteTitle' }),
      okText: intl.formatMessage({ id: 'common.btn.confirm' }),
      onOk() {
        return deleteMultipleStoreTypesMutation.mutateAsync(selectedRowKeys, {
          onSuccess() {
            refetch();

            setSelectedRowKeys([]);
            setSelectedRows([]);
            Notification(
              'success',
              intl.formatMessage({ id: 'message.deleteSuccess' }),
            );
          },
          onError(error) {
            Notification(
              'error',
              error?.message ||
                intl.formatMessage({ id: 'message.commonError' }),
            );
          },
        });
      },
      onCancel() {},
    });
  };

  const handleClickEdit = () => {
    if (selectedRows.length !== 1) {
      return;
    }

    setIsOpenModal(true);
    setSelectedValue(selectedRows[0]);
  };

  return (
    <>
      <StyledActions size={24}>
        <StyledButton type="primary" onClick={() => setIsOpenModal(true)}>
          {intl.formatMessage({ id: 'common.btn.addNew' })}
        </StyledButton>

        <StyledButton
          disabled={selectedRows.length !== 1}
          onClick={handleClickEdit}
        >
          {intl.formatMessage({ id: 'common.btn.edit' })}
        </StyledButton>

        <StyledButton
          onClick={handleDelete}
          type="danger"
          disabled={selectedRowKeys.length <= 0}
        >
          {intl.formatMessage({ id: 'common.btn.delete' })}
        </StyledButton>
      </StyledActions>

      <Table
        rowKey="id"
        rowSelection={{
          selectedRowKeys,
          onChange: (newSelectedRowKeys, newSelectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setSelectedRows(newSelectedRows);
          },
          preserveSelectedRowKeys: true,
        }}
        columns={columns}
        dataSource={data || []}
        loading={
          isLoading || isFetching || deleteMultipleStoreTypesMutation.isLoading
        }
        pagination={false}
      />

      <AddEditStoreTypeModal
        open={isOpenModal}
        onCancel={() => {
          setIsOpenModal(false);
          setSelectedValue(null);
        }}
        onSuccess={() => {
          setIsOpenModal(false);
          refetch();
          setSelectedValue(null);
          setSelectedRowKeys([]);
          setSelectedRows([]);
        }}
        initialValues={selectedValue}
      />
    </>
  );
}
